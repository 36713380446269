<template>
  <div class="container" fluid="lg">
    <div class="row justify-content-center">
      <div class="col-12 bg-primary-lighter">
        <router-link to="/login">
          <h6 class="text-primary text-center py-4">
            {{ $t('have_account') }}
          </h6>
        </router-link>
      </div>

      <div class="col-12 pt-4">
        <h4 class="text-center">{{ $t('welcome_to_synpitarn') }}</h4>
      </div>

      <div class="col-12 col-md-6">
        <b-form @submit.prevent="showConfirmDialog">
          <div class="row">
            <div class="col-12 pt-4">
              <div class="d-flex justify-content-between">
                <h6>
                  {{ $t('name.label') }}
                  <span class="small">({{ $t('name_english_only') }})</span>
                  <span v-if="!$v.name.required || !$v.name.isLetter" class="text-danger">*</span>
                </h6>

                <get-help modal_id="modal-name" :content="$t('name.help')" />
              </div>
              <b-form-input
                v-model.trim="$v.name.$model"
                size="sm"
                :class="{ error: $v.name.$error }"
                placeholder
                type="text"
              ></b-form-input>
              <div class="small" :class="$v.name.isLetter ? 'text-light' : 'text-danger'">
                {{ $v.name.isLetter ? $t('name.example') : $t('name_english_only') }}
              </div>
            </div>

            <div class="small text-center text-danger p-4">
              {{ $t('phone_number.help') }}
            </div>

            <div class="col-12 pt-4">
              <div class="d-flex justify-content-between">
                <h6>
                  {{ $t('phone_number.label') }}
                  <span v-if="!$v.phone.required || !$v.phone.minLength || !$v.phone.maxLength" class="text-danger"
                    >*</span
                  >
                </h6>
              </div>
              <b-form-input
                v-model.trim="$v.phone.$model"
                v-mask="'##########'"
                size="sm"
                :class="{ error: $v.phone.$error }"
                placeholder
                type="tel"
              ></b-form-input>
              <div class="small text-light">
                {{ $t('phone_number.example') }}
              </div>
            </div>

            <div class="col-12 pt-4">
              <div class="d-flex justify-content-between">
                <h6>
                  {{ $t('date_of_birth.label') }}
                  <span v-if="!$v.dob.required" class="text-danger">*</span>
                </h6>
                <get-help modal_id="modal-dob" title="What do we mean by 'name'" :content="$t('date_of_birth.help')" />
              </div>

              <!-- <div class="position-relative">
              <b-form-input
                size="sm"
                v-model.trim="$v.dob.$model"
                :class="{ error: $v.dob.$error }"
                disabled
              ></b-form-input>

              <i
                class="append-icon ri-calendar-2-line ri-lg"
                @click="showDatePicker()"
              ></i>
            </div>-->

              <VueDatePicker
                ref="menu"
                v-model="date"
                :format="format"
                :min-date="minDate"
                :max-date="maxDate"
                fullscreen-mobile
                color="#2E3192"
                @onOpen="menu = true"
                @onClose="menu = false"
              >
                <template #activator>
                  <div class="position-relative w-100">
                    <b-form-input
                      v-model.trim="$v.dob.$model"
                      size="sm"
                      :class="{ error: $v.dob.$error }"
                      disabled
                    ></b-form-input>

                    <i class="append-icon ri-calendar-2-line ri-lg"></i>
                  </div>
                </template>
              </VueDatePicker>

              <!-- <b-modal
              id="modal-date-picker"
              centered
              scrollable
              size="sm"
              hide-header
              footer-class="m-0 p-2"
              content-class="border-0 w-auto"
              cancel-variant="transparent"
              ok-variant="transparent text-primary"
              buttonSize="sm"
              ok-only
            >
              <VueDatePicker
                v-model="date"
                :format="format"
                :min-date="minDate"
                :max-date="maxDate"
                :locale="locale"
                @onOpen="menu = true"
                @onClose="menu = false"
                color="#2E3192"
                inline
              >
              </VueDatePicker>
            </b-modal>-->
              <div class="small text-light">
                {{ $t('date_of_birth.example') }}
              </div>
            </div>

            <div class="col-12 pt-4">
              <div class="d-flex justify-content-between">
                <h6>
                  {{ $t('province_of_work.label') }}
                  <span v-if="!$v.province_work.required" class="text-danger">*</span>
                </h6>
                <get-help
                  modal_id="modal-pow"
                  title="What do we mean by 'name'"
                  :content="$t('province_of_work.help')"
                />
              </div>

              <multiselect
                v-model.trim="$v.province_work.$model"
                :class="{ error: $v.province_work.$error }"
                track-by="name"
                label="name"
                :placeholder="$t('select_one')"
                :options="province_work_options"
                :searchable="false"
                :allow-empty="false"
                deselect-label=""
                :show-labels="false"
              >
                <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
              </multiselect>

              <div class="small text-light">
                {{ $t('province_of_work.example') }}
              </div>
            </div>

            <div class="col-12 pt-4">
              <div class="d-flex justify-content-between">
                <h6>
                  {{ $t('province_of_residence.label') }}
                  <span v-if="!$v.province_resident.required" class="text-danger">*</span>
                </h6>
                <get-help
                  modal_id="modal-por"
                  title="What do we mean by 'name'"
                  :content="$t('province_of_residence.help')"
                />
              </div>
              <multiselect
                v-model.trim="$v.province_resident.$model"
                :class="{ error: $v.province_resident.$error }"
                track-by="name"
                label="name"
                :placeholder="$t('select_one')"
                :options="province_resident_options"
                :searchable="false"
                :allow-empty="false"
                deselect-label=""
                :show-labels="false"
              >
                <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
              </multiselect>
              <div class="small text-light">
                {{ $t('province_of_residence.example') }}
              </div>
            </div>

            <div class="col-12 pt-4">
              <div class="d-flex justify-content-between">
                <h6>
                  {{ $t('income_type.label') }}
                  <span v-if="!$v.income_type.required" class="text-danger">*</span>
                </h6>
              </div>
              <multiselect
                v-model.trim="$v.income_type.$model"
                :class="{ error: $v.income_type.$error }"
                track-by="key"
                label="name"
                :placeholder="$t('select_one')"
                :options="income_type_options"
                :searchable="false"
                :allow-empty="false"
                deselect-label=""
                :show-labels="false"
              >
                <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
              </multiselect>
              <div class="small text-light">
                {{ $t('income_type.example') }}
              </div>
            </div>

            <div class="col-12 pt-4">
              <div class="d-flex justify-content-between">
                <h6>
                  {{ $t('salary.label') }}
                  <span v-if="!$v.salary.required" class="text-danger">*</span>
                </h6>
                <get-help modal_id="modal-salary" title="What do we mean by 'name'" :content="$t('salary.help')" />
              </div>
              <b-form-input
                v-model.trim="$v.salary.$model"
                v-mask="'########'"
                type="number"
                size="sm"
                :class="{ error: $v.salary.$error }"
                placeholder
              ></b-form-input>
              <div class="small text-light">{{ $t('salary.example') }}</div>
            </div>

            <div class="col-12 pt-4 d-flex">
              <b-form-checkbox
                v-model.trim="$v.accept.$model"
                :class="{ error: $v.accept.$error }"
                size="lg"
                class="d-inline-block"
                @change="accept ? $bvModal.show('modal-term-cod') : ''"
              ></b-form-checkbox>

              <div class="d-inline-block small">
                {{ $t('i_agree_term_condition') }}
              </div>
            </div>

            <div class="small text-center text-danger p-4">
              {{ $t('term_condition_confirm_text') }}
            </div>

            <div class="col-12 py-4 text-center">
              <b-button type="submit" pill variant="primary px-4">{{ $t('continue') }}</b-button>
            </div>
          </div>
        </b-form>

        <b-modal
          id="modal-term-cod"
          centered
          scrollable
          hide-footer
          hide-header-close
          :title="$t('term_and_condition')"
          title-class="text-center"
          no-close-on-backdrop
        >
          <div class="p-4 text-center">
            <!-- <h6 class="text-left">{{ $t("what_we_do_content") }}</h6> -->
            <English v-if="$i18n.locale == 'en'" />
            <Thailand v-else-if="$i18n.locale == 'th'" />
            <Myanmar v-else />
            <b-button pill variant="primary px-4 mt-4" @click="agreeTermCod()">{{
              $t('agree_term_condition')
            }}</b-button>
          </div>
        </b-modal>

        <b-modal
          id="modal-confirm"
          centered
          scrollable
          hide-footer
          :title="$t('conformation')"
          title-class="text-center"
        >
          <b-form @submit.prevent="registerAccount">
            <div class="text-center pb-4">
              <b-table striped borderless :items="confirm_items" thead-class="d-none">
                <template #cell(name)="data">
                  <div class="text-left">{{ data.value }}</div>
                </template>
                <template #cell(value)="data">
                  <div class="text-right">{{ data.value }}</div>
                </template>
              </b-table>
              <!-- <router-link to="/confirm-otp"> -->
              <b-button type="sumbit" pill variant="primary px-4">
                {{ $t('continue') }}
              </b-button>
              <!-- </router-link> -->
            </div>
          </b-form>
        </b-modal>

        <loading :is-busy="isBusy"></loading>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import '@/assets/scss/vue-datepicker.min.css'
import '@/assets/scss/vue-multiselect.min.scss'
import moment from 'moment'
import { required, sameAs, minLength, maxLength } from 'vuelidate/lib/validators'
import AccountService from '@/api/service/AccountService'
import CommonService from '@/api/service/CommonService'

import GetHelp from '@/components/GetHelp'
import Loading from '@/components/Loading.vue'

// Term and Condition
import Myanmar from '@/components/tc/Myanmar.vue'
import English from '@/components/tc/English.vue'
import Thailand from '@/components/tc/Thailand.vue'

const isLetter = (value) => /^[A-Za-z\s]+$/.test(value)

const date = new Date()
export default {
  components: {
    Multiselect,
    GetHelp,
    Loading,
    Myanmar,
    English,
    Thailand,
  },
  data() {
    return {
      menu: false,
      name: this.$store.getters['register/name'],
      phone: this.$store.getters['register/phone'],
      date,
      minDate: `${date.getFullYear() - 100}-${date.getMonth() + 1}-${date.getDate()}`,
      maxDate: `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`,
      format: 'YYYY-MM-DD',
      dob: this.$store.getters['register/dob'],
      // selectedDate: this.$store.getters["register/dob"],
      province_work: this.$store.getters['register/provinceWork'],
      province_resident: this.$store.getters['register/provinceResident'],
      income_type: this.$store.getters['register/incomeType'],
      salary: this.$store.getters['register/salary'],
      social_media: this.$store.getters['register/socialMedia'],
      accept: this.$store.getters['register/accept'],
      province_work_options: [],
      province_resident_options: [],
      income_type_options: [],
      confirm_items: [],
      isBusy: false,
      errorMessageList: [],
    }
  },
  watch: {
    date: function (val) {
      if (val) {
        this.dob = val
        const current = moment()
        if (current.diff(val, 'years') < 20) {
          this.dob = ''
          this.$bvModal.hide('modal-date-picker')
          this.showAlert(this.$t('age_at_least'))
        } else if (current.diff(val, 'years') > 60) {
          this.dob = ''
          this.$bvModal.hide('modal-date-picker')
          this.showAlert(this.$t('age_at_most'))
        }
      }
    },
    menu(val) {
      val && setTimeout(() => (this.$refs.menu.$refs.agenda.mode = 'year'))
    },
  },
  created() {
    this.getInitData()
  },
  methods: {
    async getInitData() {
      try {
        this.isBusy = true
        const provinceResponse = await CommonService.getProvince()
        const incomeTypeResponse = await CommonService.getIncomeType()

        const provinces = provinceResponse.data.data
        const incomeType = incomeTypeResponse.data.data

        provinces.forEach((province) => {
          if (province.status === 'show') {
            this.province_work_options.push({
              id: province.id,
              name:
                this.$i18n.locale == 'mm'
                  ? province.name_mm
                  : this.$i18n.locale == 'en'
                  ? province.name_en
                  : province.name_th,
            })
            this.province_resident_options.push({
              id: province.id,
              name:
                this.$i18n.locale == 'mm'
                  ? province.name_mm
                  : this.$i18n.locale == 'en'
                  ? province.name_en
                  : province.name_th,
            })
          }
        })

        incomeType.forEach((income) => {
          this.income_type_options.push({
            key: income.key,
            name:
              this.$i18n.locale == 'mm' ? income.name_mm : this.$i18n.locale == 'en' ? income.name_en : income.name_th,
          })
        })

        // this.income_type_options = incomeTypeResponse.data.data;

        this.isBusy = false
      } catch (err) {
        this.isBusy = false
        this.showAlert(err)
      }
    },
    showDatePicker() {
      this.$bvModal.show('modal-date-picker')
    },
    agreeTermCod() {
      this.$bvModal.hide('modal-term-cod')
      this.accept = true
    },
    showConfirmDialog() {
      if (this.$v.$invalid) {
        this.$v.$touch()
      } else {
        this.getConfirmItem()
        this.$bvModal.show('modal-confirm')
      }
    },
    getConfirmItem() {
      //reset item first to prevent duplicate
      this.confirm_items = []
      this.confirm_items.push(
        {
          name: this.$t('name.label'),
          value: this.name,
        },
        { name: this.$t('phone_number.label'), value: this.phone },
        { name: this.$t('date_of_birth.label'), value: this.dob },
        {
          name: this.$t('province_of_work.label'),
          value: this.province_work ? this.province_work.name : '',
        },
        {
          name: this.$t('province_of_residence.label'),
          value: this.province_resident ? this.province_resident.name : '',
        },
        {
          name: this.$t('income_type.label'),
          value: this.income_type ? this.income_type.name : '',
        },
        { name: this.$t('salary.label'), value: this.salary }
        // { name: this.$t("social_media.label"), value: this.social_media }
      )
    },
    async registerAccount() {
      this.$bvModal.hide('modal-confirm')
      if (this.$v.$invalid) {
        this.$v.$touch()
      } else {
        this.isBusy = true

        this.$store.dispatch('register/saveRegisterInfo', {
          payload: {
            pincode: this.pincode,
            name: this.name,
            phone: this.phone,
            dob: this.dob,
            provinceWork: this.province_work,
            provinceResident: this.province_resident,
            incomeType: this.income_type,
            salary: this.salary,
            socialMedia: this.social_media,
            accept: this.accept,
          },
        })

        //delete cache first, when it is exists
        try {
          await AccountService.destoryCache({
            phone_number: this.phone,
          })
        } catch (err) {
          console.log(err)
        }

        try {
          const res = await AccountService.register({
            name: this.name,
            phone_number: this.phone,
            dob: this.dob,
            province_of_work: this.province_work ? this.province_work.id : null,
            province_of_resident: this.province_resident ? this.province_resident.id : null,
            income_type: this.income_type ? this.income_type.key : null,
            salary: this.salary,
            email: this.social_media,
          })

          this.isBusy = false

          if (res.status == 200) {
            this.getOTP()
          }
        } catch (err) {
          this.isBusy = false
          // this.errorMessageList = [];
          // for (const e in err) {
          //   err[e].forEach(e => {
          //     this.errorMessageList.push(e);
          //   });
          // }
          this.showAlert(err)
        }
      }
    },

    async getOTP() {
      this.isBusy = true
      try {
        const res = await AccountService.getOTP({
          phone_number: this.phone,
        })

        this.isBusy = false

        if (res.status == 200) {
          if (process.env.VUE_APP_ENVIROMENT === 'local' || process.env.VUE_APP_ENVIROMENT === 'staging') {
            this.$store.dispatch('register/saveOtpCode', {
              otpCode: res.data.data,
            })
          }

          this.$router.push({ path: 'confirm-otp' })
        }
      } catch (err) {
        this.isBusy = false
        this.showAlert(err)
      }
    },
    // isLetter(e) {
    //   let char = String.fromCharCode(e.keyCode); // Get the character
    //   if (/^[A-Za-z\s]+$/.test(char)) return true;
    //   // Match with regex
    //   else e.preventDefault(); // If not match, don't add to input text
    // }
  },
  validations: {
    name: {
      required,
      isLetter,
    },
    phone: {
      required,
      minLength: minLength(10),
      maxLength: maxLength(10),
    },
    dob: {
      required,
    },
    province_work: {
      required,
    },
    province_resident: {
      required,
    },
    income_type: {
      required,
    },
    salary: {
      required,
    },
    accept: {
      sameAs: sameAs(() => true),
    },
  },
}
</script>

<style></style>
